exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ambassador-tsx": () => import("./../../../src/pages/ambassador.tsx" /* webpackChunkName: "component---src-pages-ambassador-tsx" */),
  "component---src-pages-connecting-business-tsx": () => import("./../../../src/pages/connecting-business.tsx" /* webpackChunkName: "component---src-pages-connecting-business-tsx" */),
  "component---src-pages-consul-tech-tsx": () => import("./../../../src/pages/consul-tech.tsx" /* webpackChunkName: "component---src-pages-consul-tech-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-departments-accounting-tsx": () => import("./../../../src/pages/departments/accounting.tsx" /* webpackChunkName: "component---src-pages-departments-accounting-tsx" */),
  "component---src-pages-departments-compliance-tsx": () => import("./../../../src/pages/departments/compliance.tsx" /* webpackChunkName: "component---src-pages-departments-compliance-tsx" */),
  "component---src-pages-departments-ecommerce-tsx": () => import("./../../../src/pages/departments/ecommerce.tsx" /* webpackChunkName: "component---src-pages-departments-ecommerce-tsx" */),
  "component---src-pages-departments-energy-tsx": () => import("./../../../src/pages/departments/energy.tsx" /* webpackChunkName: "component---src-pages-departments-energy-tsx" */),
  "component---src-pages-departments-esg-tsx": () => import("./../../../src/pages/departments/esg.tsx" /* webpackChunkName: "component---src-pages-departments-esg-tsx" */),
  "component---src-pages-departments-financing-tsx": () => import("./../../../src/pages/departments/financing.tsx" /* webpackChunkName: "component---src-pages-departments-financing-tsx" */),
  "component---src-pages-departments-grants-tsx": () => import("./../../../src/pages/departments/grants.tsx" /* webpackChunkName: "component---src-pages-departments-grants-tsx" */),
  "component---src-pages-departments-health-tsx": () => import("./../../../src/pages/departments/health.tsx" /* webpackChunkName: "component---src-pages-departments-health-tsx" */),
  "component---src-pages-departments-hr-tsx": () => import("./../../../src/pages/departments/hr.tsx" /* webpackChunkName: "component---src-pages-departments-hr-tsx" */),
  "component---src-pages-departments-insurance-tsx": () => import("./../../../src/pages/departments/insurance.tsx" /* webpackChunkName: "component---src-pages-departments-insurance-tsx" */),
  "component---src-pages-departments-investor-relations-tsx": () => import("./../../../src/pages/departments/investor-relations.tsx" /* webpackChunkName: "component---src-pages-departments-investor-relations-tsx" */),
  "component---src-pages-departments-it-tsx": () => import("./../../../src/pages/departments/it.tsx" /* webpackChunkName: "component---src-pages-departments-it-tsx" */),
  "component---src-pages-departments-legal-tsx": () => import("./../../../src/pages/departments/legal.tsx" /* webpackChunkName: "component---src-pages-departments-legal-tsx" */),
  "component---src-pages-departments-management-tsx": () => import("./../../../src/pages/departments/management.tsx" /* webpackChunkName: "component---src-pages-departments-management-tsx" */),
  "component---src-pages-departments-marketing-tsx": () => import("./../../../src/pages/departments/marketing.tsx" /* webpackChunkName: "component---src-pages-departments-marketing-tsx" */),
  "component---src-pages-departments-pr-tsx": () => import("./../../../src/pages/departments/pr.tsx" /* webpackChunkName: "component---src-pages-departments-pr-tsx" */),
  "component---src-pages-departments-real-estate-tsx": () => import("./../../../src/pages/departments/real-estate.tsx" /* webpackChunkName: "component---src-pages-departments-real-estate-tsx" */),
  "component---src-pages-departments-security-tsx": () => import("./../../../src/pages/departments/security.tsx" /* webpackChunkName: "component---src-pages-departments-security-tsx" */),
  "component---src-pages-departments-tax-tsx": () => import("./../../../src/pages/departments/tax.tsx" /* webpackChunkName: "component---src-pages-departments-tax-tsx" */),
  "component---src-pages-departments-training-tsx": () => import("./../../../src/pages/departments/training.tsx" /* webpackChunkName: "component---src-pages-departments-training-tsx" */),
  "component---src-pages-discounts-tsx": () => import("./../../../src/pages/discounts.tsx" /* webpackChunkName: "component---src-pages-discounts-tsx" */),
  "component---src-pages-goodnet-tsx": () => import("./../../../src/pages/goodnet.tsx" /* webpackChunkName: "component---src-pages-goodnet-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-relations-tsx": () => import("./../../../src/pages/investor-relations.tsx" /* webpackChunkName: "component---src-pages-investor-relations-tsx" */),
  "component---src-pages-plans-business-tsx": () => import("./../../../src/pages/plans/business.tsx" /* webpackChunkName: "component---src-pages-plans-business-tsx" */),
  "component---src-pages-plans-company-plus-tsx": () => import("./../../../src/pages/plans/company-plus.tsx" /* webpackChunkName: "component---src-pages-plans-company-plus-tsx" */),
  "component---src-pages-plans-company-tsx": () => import("./../../../src/pages/plans/company.tsx" /* webpackChunkName: "component---src-pages-plans-company-tsx" */),
  "component---src-pages-plans-mini-tsx": () => import("./../../../src/pages/plans/mini.tsx" /* webpackChunkName: "component---src-pages-plans-mini-tsx" */),
  "component---src-pages-plans-personal-tsx": () => import("./../../../src/pages/plans/personal.tsx" /* webpackChunkName: "component---src-pages-plans-personal-tsx" */),
  "component---src-pages-public-offer-tsx": () => import("./../../../src/pages/public-offer.tsx" /* webpackChunkName: "component---src-pages-public-offer-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

